<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.accName" placeholder="角色名称" allowClear />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchData">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
      </a-form>
    </template>

    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <template #ratio="{ record }">
        {{ record.ratio }}%
      </template>
      <template #accName="{ record }">
        <div>
          <a @click="onEditShow(record)">{{ record.accName }}</a>
        </div>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="edit(record)">修改</a>
        </span>
      </template>
      <template #status="{ record }">
        <div>
          <a-switch :checkedValue="1" :unCheckedValue="0" v-model:checked="record.state" checkedChildren="启用"
            unCheckedChildren="禁用" @change="onSave(record)" />
        </div>
        <!-- <a-popconfirm :title="`你确定${record.state ? '启用' : '禁用'}该账号吗？`" @confirm="onSave(record)">
        </a-popconfirm> -->
      </template>
    </a-table>
  </suy-table>
  <a-modal :footer="null" v-if="cloudShow" v-model:visible="cloudShow" title="详情" width="1200px" @cancel="onCancel"
    :maskClosable="false">
    <CloudFlashc :propsBizUserId="bizUserId" ref="_cloudFlash" @addSuccess="addSuccess" />
  </a-modal>
  <a-modal v-model:visible="modalVisible" :title="modalTitle" :maskClosable="false" :confirm-loading="confirmLoading"
    @ok="save">
    <a-form ref="formRef" :model="form" layout="vertical">
      <a-form-item label="分账比例">
        <a-input type="number" v-model:value="form.ratio" suffix="%" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>

import { internalAccList, editInternalAcc } from '@/api/yst/yst'
import { toRefs, reactive, onMounted, createVNode, ref } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
import CloudFlashc from '../../carrier/toolDetail/cloudFlashc'

export default {
  name: 'role',
  setup () {
    const _cloudFlash = ref(null)
    const state = reactive({
      loading: false,
      cloudShow: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {},
      modalVisible: false,
      modalTitle: '',
      bizUserId: '',
      form: {},
      listData: [],
      sortColumn: {},
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'accName',
          slots: { customRender: 'accName' },
          align: 'center'
        },
        {
          title: '商户系统用户标识',
          dataIndex: 'bizUserId',
          align: 'center'
        },
        {
          title: '状态',
          slots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '分账比例',
          dataIndex: 'ratio',
          align: 'center',
          slots: { customRender: 'ratio' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 200,
          slots: { customRender: 'operation' }
        }
      ],
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      }
    })

    const loadData = () => {
      state.loading = true
      internalAccList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
          // state.listData.forEach(e => {
          //   if (e.state.value === 1) {
          //     e.check = true
          //   } else {
          //     e.check = false
          //   }
          // })
        }
      }).catch(err => {
        console.log('分页错误' + err)
      }).finally(() => {
        state.loading = false
        state.form = {}
      })
    }
    onMounted(loadData)
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const edit = item => {
      state.form = item
      state.modalVisible = true
      state.modalTitle = '修改"' + item.accName + '"'
    }

    const save = () => {
      if (state.form.ratio < 0) {
        message.error('分配比例不能是负数')
        return
      }
      Modal.confirm({
        title: () => '确认操作？',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '',
        onOk () {
          return new Promise((resolve, reject) => {
            editInternalAcc(state.form).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.modalVisible = false
                loadData()
              }
              // state.confirmLoading = false
            }).finally(() => { resolve() })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel () {
          if (state.form.state === 1) state.form.state = 0
          else state.form.state = 1
        }
      })
      // state.confirmLoading = true
      // if (state.form.state === true) state.form.state = { label: '是', value: 1 }
      // else if (state.form.state === false) state.form.state = { label: '否', value: 0 }

    }
    const onSave = item => {
      // console.log(item.check);
      // if(item.state !== null){
      //   if(item.state === 0) item.state = 1
      //   else item.state = 0,console.log(222);
      // }else item.state = 1,console.log(333);
      state.form = item
      save()
    }
    // 查看会员详情
    const onEditShow = (e) => {
      state.cloudShow = true
      state.bizUserId = e.bizUserId
      setTimeout(() => {
        _cloudFlash.value.loadData()
        state.cloudShowTitle = '会员详情'
      }, 1)
    }
    return {
      ...toRefs(state),
      _cloudFlash,
      searchData,
      onEditShow,
      handleTableChange,
      edit,
      onSave,
      save
    }
  },
  components: {
    SearchOutlined,
    CloudFlashc,
    SuyTable
  }
}
</script>
